
export default {
  name: 'BaseHeaderUserNav',
  data() {
    return {
      isFetch: false,
    }
  },
  methods: {
    toggleUserMenu() {
      this.$eventBus.$emit('userMenuToggle')
      this.$usermenu.toggle()
    },
  },
}
