import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=db8da7b2&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasePromoHeaderTop: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Promo/HeaderTop.vue').default,BaseHeaderCollections: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Header/Collections.vue').default,BaseHeaderLogo: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Header/Logo/Logo.vue').default,CoreIcon: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Core/Icon/Icon.vue').default,BaseHeaderUserNav: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Header/UserNav/UserNav.vue').default})
