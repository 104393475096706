
export default {
  // head() {
  //   const skip = !this.isVerifiedUser
  //   return {
  //     script: [{ skip, hid: 'stripe', src: '/js/bitrixChat.js', defer: true }],
  //   }
  // },
  computed: {
    isVerifiedUser() {
      return this.$auth.loggedIn && this.$auth?.user?.verified
    },
  },
  watch: {
    $route() {
      this.$store.dispatch('promo/initPromo')
    },
  },
  mounted() {
    if (!this.$auth.loggedIn && google) {
      google.accounts.id.initialize({
        client_id:
          '351992118372-h98mrdko377bpn8c6kpl6d3akp17u1jc.apps.googleusercontent.com',
        callback: this.handleCredentialResponse,
        context: 'signin',
      })
      google.accounts.id.prompt()
    }

    if (this.$auth.loggedIn && this.$auth?.user?.verified) {
      const userId = this.$auth?.user?._id
      const userName = this.$author?.authorName(this.$auth?.user)
      const userAvatar = `https://img.megasreda.ru/optimize?src=${this.$author?.avatar(
        this.$auth?.user
      )}&size=48&format=jpeg&quality=100`

      const profileLink = `https://megasreda.ru/${this.$auth?.user?.username}`

      window.addEventListener('onBitrixLiveChat', function (event) {
        const widget = event.detail.widget

        widget.setCustomData([
          {
            USER: {
              NAME: userName,
              AVATAR: userAvatar,
              LINK: profileLink,
            },
          },
          {
            GRID: [
              {
                NAME: 'Id профиля',
                VALUE: userId,
                DISPLAY: 'LINE',
              },
            ],
          },
        ])
      })
    }
  },

  methods: {
    async handleCredentialResponse(response) {
      const userData = await this.$axios.$get('/auth/google/gsi', {
        params: {
          credential: response.credential,
          registerFrom: this.$cookies.get('welcomeReferral'),
        },
      })

      if (userData) {
        this.$auth.setUserToken(userData.accessToken).then(() => {
          this.$auth.setUser(userData.user)
          if (userData.isNewUser) {
            // Для шагов после регистрации
            this.$cookies.set('newUser', true, {
              path: '/',
              maxAge: 60 * 60 * 24 * 30 * 12,
            })
          }
        })

        window.location.replace('/')
      }
    },
    async handleYandexResponse(data) {
      const userData = await this.$axios.$get('/auth/yandex/gsi', {
        params: {
          credential: data,
        },
      })

      if (userData) {
        this.$auth.setUserToken(userData.accessToken).then(() => {
          this.$auth.setUser(userData.user)
          if (userData.isNewUser) {
            // Для шагов после регистрации
            this.$cookies.set('newUser', true, {
              path: '/',
              maxAge: 60 * 60 * 24 * 30 * 12,
            })
          }
        })

        window.location.replace('/')
      }
    },
  },
}
