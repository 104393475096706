
export default {
  name: 'BasePromoHeaderTop',
  computed: {
    promo() {
      return this.$store.getters['promo/getHeaderTopPromo']
    },
  },
  methods: {
    closeHeaderTopPromo() {
      this.$store.dispatch('promo/closeHeaderTopPromo')
    },
  },
}
