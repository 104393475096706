
export default {
  name: 'BaseHeaderCollections',
  data() {
    return {
      collections: [],
    }
  },
  async fetch() {
    this.collections = await this.$axios.$get('/collection')
  },
}
