
export default {
  name: 'BaseMenuItem',
  props: {
    link: {
      type: Object,
      default: null,
    },
  },

  methods: {
    getSub(sub) {
      return this.$store.getters[`menu/${sub}`]
    },
  },
}
