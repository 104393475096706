
export default {
  name: 'BaseProfileStreamLogo',
  props: {
    stream: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
    noLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imgSize() {
      switch (this.size) {
        case 'thin':
          return 16
        case 'light':
          return 24
        case 'small':
          return 44
        case 'large':
          return 92

        default:
          return 92
      }
    },
  },
}
