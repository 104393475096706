var render = function render(){
  var _vm$streams, _vm$subscriptions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "z-40 flex-shrink-0 w-menu bottom-0 left-0 transition-transform duration-300",
    class: {
      'top-header-mobile tab-l:top-header': !_vm.$header.isHeaderTopPromoVisible,
      'top-header-promo-phone phone-l:top-header-promo-phone-l  tab:top-header-promo-tab tab-l:top-header-promo ': _vm.$header.isHeaderTopPromoVisible,
      'fixed bg-primary-white': !_vm.included,
      'fixed h-height-menu-mobile tab-l:h-height-menu bg-primary-white desktop-l:bg-primary-bg shadow-md desktop-l:shadow-none': _vm.included && !_vm.$header.isHeaderTopPromoVisible,
      'fixed h-height-menu-promo-phone phone-l:h-height-menu-promo-phone-l tab:h-height-menu-promo-tab tab-l:h-height-menu-promo bg-primary-white desktop-l:bg-primary-bg shadow-md desktop-l:shadow-none': _vm.included && _vm.$header.isHeaderTopPromoVisible,
      'translate-x-0': _vm.$menu.isVisible && _vm.included,
      'hidden desktop-l:flex': !_vm.$menu.isVisible && _vm.included,
      'translate-x-0 shadow-md': _vm.$menu.isVisible && !_vm.included,
      '-translate-x-menu': !_vm.$menu.isVisible && !_vm.included
    },
    attrs: {
      "id": "main-menu"
    }
  }, [_c('PerfectScrollbar', {
    staticClass: "flex flex-col px-3 pt-4 pb-6 h-full",
    attrs: {
      "options": {
        suppressScrollX: true,
        wheelPropagation: false,
        wheelSpeed: 0.5
      }
    }
  }, [_c('ul', _vm._l(_vm.$menu.mainLinks, function (link) {
    return _c('li', {
      key: link.id
    }, [(!link.private || _vm.$auth.loggedIn) && (!link.forModerator || link.forModerator && _vm.isModerator) ? _c('BaseMenuItem', {
      attrs: {
        "link": link
      }
    }) : _vm._e()], 1);
  }), 0), _vm._v(" "), _vm.$auth.loggedIn ? [_c('hr', {
    staticClass: "my-2.5 border-gray-5"
  }), _vm._v(" "), _c('ul', [_c('li', {
    staticClass: "flex items-center"
  }, [_c('nuxt-link', {
    staticClass: "w-full flex items-center text-gray-2 hover:bg-primary-white rounded-xl relative text-comment px-3 py-2.5",
    attrs: {
      "to": `/${_vm.$auth.user.username}`
    }
  }, [_c('BaseUserAvatar', {
    staticClass: "mr-2.5",
    attrs: {
      "no-link": "",
      "user": _vm.$auth.user,
      "size": "thin"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "transition"
  }, [_vm._v(_vm._s(_vm.myName))])], 1)], 1), _vm._v(" "), _vm._l(_vm.$menu.userLinks, function (link) {
    return _c('li', {
      key: link.id
    }, [link.access === 'verified' && _vm.$auth.user.verified || !link.access ? _c('BaseMenuItem', {
      staticClass: "py-2.5",
      attrs: {
        "link": link
      }
    }) : _vm._e()], 1);
  })], 2)] : _vm._e(), _vm._v(" "), (_vm$streams = _vm.streams) !== null && _vm$streams !== void 0 && _vm$streams.length ? _c('div', {
    staticClass: "flex flex-col"
  }, [_c('hr', {
    staticClass: "my-2.5 border-gray-5"
  }), _vm._v(" "), _c('h3', {
    staticClass: "my-2.5 px-3 text-gray-1 font-medium text-comment"
  }, [_vm._v("\n        ПОПУЛЯРНОЕ\n      ")]), _vm._v(" "), _c('ul', _vm._l(_vm.streams, function (item) {
    return _c('li', {
      key: item._id
    }, [_c('nuxt-link', {
      staticClass: "flex items-center text-gray-2 hover:bg-primary-white rounded-xl text-comment px-3 py-2.5",
      attrs: {
        "to": `/stream/${item.stream.url}`
      }
    }, [_c('BaseProfileStreamLogo', {
      staticClass: "mr-2.5",
      attrs: {
        "no-link": "",
        "stream": item.stream,
        "size": "thin"
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "transition line-clamp-1 text-comment"
    }, [_vm._v(_vm._s(item.stream.title))])], 1)], 1);
  }), 0), _vm._v(" "), _c('nuxt-link', {
    staticClass: "mt-3 px-3 flex items-center text-gray-1 hover:text-accent",
    attrs: {
      "to": "/navigator/streams"
    }
  }, [_c('span', {
    staticClass: "transition text-comment"
  }, [_vm._v("Смотреть все")]), _c('CoreIcon', {
    staticClass: "w-4 h-4 ml-1 fill-current transform -rotate-90 transition",
    attrs: {
      "name": "arrow-down"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), !!_vm.isUserHasSubscriptions ? _c('div', {
    staticClass: "flex flex-col"
  }, [_c('hr', {
    staticClass: "my-2.5 border-gray-5"
  }), _vm._v(" "), _c('h3', {
    staticClass: "my-2.5 px-3 text-gray-1 font-medium text-comment"
  }, [_vm._v("\n        ПОДПИСКА\n      ")]), _vm._v(" "), _c('ul', _vm._l(_vm.subscriptions, function (entity) {
    var _entity$data, _entity$data$company, _entity$data2, _entity$data2$company, _entity$data3, _entity$data4, _entity$data4$company, _entity$data5, _entity$data5$company, _entity$data6;
    return _c('li', {
      key: entity.data._id
    }, [entity.type === 'stream' ? [_c('nuxt-link', {
      staticClass: "flex items-center text-gray-2 hover:bg-primary-white rounded-xl text-comment px-3 py-2.5",
      attrs: {
        "to": `/stream/${entity.data.stream.url}`,
        "title": `Профиль журнала «${entity.data.stream.title}» на Мегасреде`
      }
    }, [_c('BaseProfileStreamLogo', {
      staticClass: "mr-2.5",
      attrs: {
        "no-link": "",
        "stream": entity.data.stream,
        "size": "thin"
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "transition line-clamp-1 text-comment"
    }, [_vm._v(_vm._s(entity.data.stream.title))])], 1)] : _vm._e(), _vm._v(" "), entity.type === 'company' ? [_c('nuxt-link', {
      staticClass: "w-full flex items-center text-gray-2 hover:bg-primary-white rounded-xl relative text-comment px-3 py-2.5",
      attrs: {
        "to": `/${entity.data.username}`,
        "title": `Профиль компании «${(_entity$data = entity.data) !== null && _entity$data !== void 0 && (_entity$data$company = _entity$data.company) !== null && _entity$data$company !== void 0 && _entity$data$company.title ? (_entity$data2 = entity.data) === null || _entity$data2 === void 0 ? void 0 : (_entity$data2$company = _entity$data2.company) === null || _entity$data2$company === void 0 ? void 0 : _entity$data2$company.title : (_entity$data3 = entity.data) === null || _entity$data3 === void 0 ? void 0 : _entity$data3.username}» на Мегасреде`
      }
    }, [_c('BaseUserAvatar', {
      staticClass: "mr-2.5",
      attrs: {
        "no-link": "",
        "user": entity.data,
        "size": "thin"
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "transition line-clamp-1 text-comment"
    }, [_vm._v(_vm._s((_entity$data4 = entity.data) !== null && _entity$data4 !== void 0 && (_entity$data4$company = _entity$data4.company) !== null && _entity$data4$company !== void 0 && _entity$data4$company.title ? (_entity$data5 = entity.data) === null || _entity$data5 === void 0 ? void 0 : (_entity$data5$company = _entity$data5.company) === null || _entity$data5$company === void 0 ? void 0 : _entity$data5$company.title : (_entity$data6 = entity.data) === null || _entity$data6 === void 0 ? void 0 : _entity$data6.username))])], 1)] : _vm._e(), _vm._v(" "), entity.type === 'user' ? [_c('nuxt-link', {
      staticClass: "w-full flex items-center text-gray-2 hover:bg-primary-white rounded-xl relative text-comment px-3 py-2.5",
      attrs: {
        "to": `/${entity.data.username}`,
        "title": `Профиль пользователя «${_vm.userName(entity.data)}» на Мегасреде`
      }
    }, [_c('BaseUserAvatar', {
      staticClass: "mr-2.5",
      attrs: {
        "no-link": "",
        "user": entity.data,
        "size": "thin"
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "transition line-clamp-1 text-comment"
    }, [_vm._v(_vm._s(_vm.userName(entity.data)))])], 1)] : _vm._e()], 2);
  }), 0), _vm._v(" "), ((_vm$subscriptions = _vm.subscriptions) === null || _vm$subscriptions === void 0 ? void 0 : _vm$subscriptions.length) >= 10 ? _c('nuxt-link', {
    staticClass: "flex items-center mt-2.5 px-3 text-gray-1 hover:text-accent",
    attrs: {
      "to": `/${_vm.$auth.user.username}/subscriptions`,
      "title": "Смотреть все свои подписки"
    }
  }, [_c('span', {
    staticClass: "transition text-comment"
  }, [_vm._v("Смотреть все")]), _c('CoreIcon', {
    staticClass: "w-4 h-4 ml-1 fill-current transform -rotate-90 transition",
    attrs: {
      "name": "arrow-down"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }